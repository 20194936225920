import React, { VFC, Fragment, ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Page } from '../../ui/layout/page';
import { Header } from '../../ui/layout/header';
import { Section } from '../../ui/layout/section';
import { Fieldset } from '../../ui/form/fieldset';
import { Input } from '../../ui/form/input';
import { Select } from '../../ui/form/select';
//import { SelectFiltered } from '../../ui/form/select-filtered';
import { Footnote } from '../../ui/typography';
import { Button } from '../../ui/buttons/button';
import { ButtonNextIcon, ButtonRestartIcon } from '../../ui/icons';
import { Motivation, Warning } from '../../ui/typography';
import { IChildFormData } from '@app/state/types/model';
import { supportGameMap, getSortedLanguageOptions, OptionsList } from '@app/state/types';
import { StyledForm, TwoColFieldset } from './partials/styled-form';
import { ChildcodeTool } from './partials/childcode-tool';

export interface IViewProps {
  formValues: IChildFormData;
  done: boolean;
  facilityLocked: boolean;
  teacherLocked: boolean;
  facilities: string[];
  onSubmit: (data: Partial<IChildFormData>) => void;
  onNext: () => void;
  onNewChild: () => void;
}

export const View: VFC<IViewProps> = ({
  formValues,
  done,
  facilities,
  facilityLocked,
  onSubmit,
  onNext,
  onNewChild,
}) => {
  const [langOptions] = useState<OptionsList[]>(getSortedLanguageOptions());
  const [minBirth] = useState<string>(
    new Date(new Date().getFullYear() - 13, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]
  );
  const [maxBirth] = useState<string>(
    new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]
  );
  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const property = evt.currentTarget.name;
      let value: any = null;
      if (!!evt.currentTarget.value) {
        const type = evt.currentTarget.getAttribute('data-type') || evt.currentTarget.type;
        switch (type) {
          case 'number':
            value = Number(evt.currentTarget.value);
            if (isNaN(value)) return;
            break;
          case 'date':
            value = new Date(evt.currentTarget.value);
            break;
          default:
            value = `${evt.currentTarget.value}`;
        }
      }

      onSubmit({
        [property]: value,
      });
    },
    [onSubmit]
  );

  return (
    <Page header={<Header title="Infos zum Kind erfassen" />}>
      <Section>
        <StyledForm>
          <Fieldset label="Codierung des Kindes">
            <Input
              required
              pattern="\p{Letter}{4}(?:(?:\d{2}[A-Z]{3}[a-z]{3})|(?:\d{3,4}[A-Z]{3}))"
              name="childCode"
              value={formValues.childCode ?? ''}
              onChange={onChange}
            />
          </Fieldset>
          <Fieldset>
            <Button variant="secondary" onClick={onNewChild}>
              <ButtonRestartIcon />
              NEUES KIND / LÖSCHEN
            </Button>
          </Fieldset>
          <Fieldset label="Testung durchgeführt am">
            <Input
              name="examDate"
              required
              type="date"
              value={formValues.examDate?.toISOString().substring(0, 10) ?? ''}
              onChange={onChange}
            />
          </Fieldset>
          <Fieldset label="Diagnostik">
            <Select name="diagnosis" required value={formValues.diagnosis ?? ''} onChange={onChange}>
              <option value={undefined}></option>
              <option value="start">Anfangsdiagnostik</option>
              <option value="end">Enddiagnostik</option>
            </Select>
          </Fieldset>
        </StyledForm>
        {formValues.childCode === null && (
          <ChildcodeTool
            key={formValues.childCode}
            updateChildCode={(childCode, dateOfBirth) =>
              onSubmit({
                childCode,
                dateOfBirth,
              })
            }
          />
        )}
        <StyledForm>
          {formValues.diagnosis && (
            <React.Fragment>
              {/* Line 1 */}
              <TwoColFieldset label="Name der Einrichtung">
                {facilityLocked && (
                  <Select
                    required
                    disabled={facilityLocked}
                    name="nameOfInstitute"
                    value={formValues.nameOfInstitute ?? ''}
                    onChange={onChange}
                  >
                    {facilities &&
                      facilities.map((facility) => (
                        <option key={facility} value={facility}>
                          {facility}
                        </option>
                      ))}
                  </Select>
                )}
                {!facilityLocked && (
                  <Input
                    required
                    disabled={facilityLocked}
                    name="nameOfInstitute"
                    value={formValues.nameOfInstitute ?? ''}
                    onChange={onChange}
                  />
                )}
              </TwoColFieldset>
              <TwoColFieldset label="Name der Förderkraft">
                <Input name="nameOfTeacher" required value={formValues.nameOfTeacher ?? ''} onChange={onChange} />
              </TwoColFieldset>

              {/* Line 2 */}
              <Fieldset label="Geburtsdatum des Kindes">
                <Input
                  required
                  name="dateOfBirth"
                  type="date"
                  min={minBirth}
                  max={maxBirth}
                  value={formValues.dateOfBirth?.toISOString().substring(0, 10) ?? ''}
                  onChange={onChange}
                />
              </Fieldset>
              <Fieldset label="Geschlecht">
                <Select required name="gender" value={formValues.gender ?? ''} onChange={onChange}>
                  <option value={undefined}></option>
                  <option value="male">Männlich</option>
                  <option value="female">Weiblich</option>
                  <option value="diverse">Unbekannt</option>
                </Select>
              </Fieldset>
              <Fieldset label="Herkunftssprache">
                <Select name="nativeLanguage" required value={formValues.nativeLanguage ?? ''} onChange={onChange}>
                  <option value={undefined}></option>
                  {langOptions.map((lo: OptionsList) => (
                    <option key={lo.value} value={lo.value}>
                      {lo.label}
                    </option>
                  ))}
                </Select>
              </Fieldset>
              <Fieldset label="Herkunftssprache 2">
                <Select name="nativeLanguage2" value={formValues.nativeLanguage2 ?? ''} onChange={onChange}>
                  <option value={undefined}></option>
                  {langOptions.map((lo: OptionsList) => (
                    <option key={lo.value} value={lo.value}>
                      {lo.label}
                    </option>
                  ))}
                </Select>
              </Fieldset>

              {/* Line 3 */}
              <Fieldset label="Klassenstufe">
                <Select name="classLevel" required value={formValues.classLevel ?? ''} onChange={onChange}>
                  <option value={undefined}></option>
                  <option value="daycare">Kita</option>
                  <option value="pre-school">Vorschule</option>
                  <option value="class-1">Klassenstufe 1</option>
                  <option value="class-2">Klassenstufe 2</option>
                  <option value="class-3">Klassenstufe 3</option>
                </Select>
              </Fieldset>

              {formValues.diagnosis && formValues.diagnosis === 'start' && (
                <Fieldset
                  label={
                    <Fragment>
                      Kontaktdauer<sup>1</sup> in Monaten
                    </Fragment>
                  }
                  hint="(zum Zeitpunkt der Anfangsdiagnostik)"
                >
                  <Select
                    data-type="number"
                    name="monthsOfContact"
                    required
                    value={formValues.monthsOfContact?.toFixed(0) ?? ''}
                    onChange={onChange}
                  >
                    <option value={undefined}></option>
                    <option value="1">&lt; 6 Monate</option>
                    <option value="6">6-12 Monate</option>
                    <option value="12">1 Jahr</option>
                    <option value="24">2 Jahre</option>
                    <option value="36">3 Jahre</option>
                    <option value="48">Mehr als 3 Jahre</option>
                    <option value="-1">Unbekannt</option>
                  </Select>
                </Fieldset>
              )}

              {formValues.diagnosis && formValues.diagnosis === 'end' && (
                <React.Fragment>
                  <Fieldset label="Name der MITsprache-Gruppe">
                    <Input
                      name="classDesignation"
                      required
                      onChange={onChange}
                      value={formValues.classDesignation ?? ''}
                    />
                  </Fieldset>
                  <div />
                  <div />

                  {/* Line 4 */}
                  <Fieldset label="Förderstufe" hint="(Förderfarbe)">
                    <Select name="supportLevel" required value={formValues.supportLevel ?? ''} onChange={onChange}>
                      <option value={undefined}></option>
                      <option value="purple">Lila</option>
                      <option value="blue">Blau</option>
                      <option value="orange">Orange</option>
                      <option value="red">Rot</option>
                      <option value="green">Grün</option>
                    </Select>
                  </Fieldset>
                  <Fieldset label="Förderspielnummer">
                    <Select
                      disabled={!formValues.supportLevel}
                      name="supportGame"
                      value={formValues.supportGame ?? ''}
                      onChange={onChange}
                    >
                      <option value={undefined}></option>
                      {formValues.supportLevel &&
                        supportGameMap[formValues.supportLevel].map((supportGameOption) => (
                          <option key={supportGameOption} value={supportGameOption}>
                            {supportGameOption}
                          </option>
                        ))}
                    </Select>
                  </Fieldset>
                  <Fieldset label="Förderstunden" hint="(pro Woche)">
                    <Input
                      name="supportHoursPerWeek"
                      type="number"
                      value={formValues.supportHoursPerWeek?.toFixed(0) ?? ''}
                      onChange={onChange}
                    />
                  </Fieldset>
                  <Fieldset label="Versäumte Förderstunden" hint="(im aktuellen Förderjahr)">
                    <Input
                      name="supportHoursMissed"
                      type="number"
                      value={formValues.supportHoursMissed?.toFixed(0) ?? ''}
                      onChange={onChange}
                    />
                  </Fieldset>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </StyledForm>
        {formValues.diagnosis && formValues.diagnosis === 'start' && (
          <Footnote>
            <sup>1</sup>Hier bitte eintragen, wie lange das Kind bereits in einem aktiven Kontakt (Hören und Reden) zur
            deutschen Sprache steht. Geben Sie an, wie lange das Kind öffentliche Bildungseinrichtungen (Kita, Schulen)
            besucht hat. Wenn Sie nichts genaues wissen, wählen Sie bitte „unbekannt“ aus.
          </Footnote>
        )}
      </Section>
      <Section variant="done">
        <Button variant="primary" onClick={onNext} disabled={!done}>
          <ButtonNextIcon />
          WEITER
        </Button>
        {done ? <Motivation>Alles erfasst!</Motivation> : <Warning>Bitte füllen Sie alles aus!</Warning>}
      </Section>
    </Page>
  );
};
